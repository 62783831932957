import axios from "axios";
import { useMutation } from "react-query";

import { CommonResponse } from "@ag/utils/types";

import { CompanyLookup, CompanyLookupSchema } from "../entities/company";

export type LookUpCompanyParams = {
  vatNumber: string;
  countryCode: string;
};

const lookUpCompany = async (data: LookUpCompanyParams) => {
  const response = await axios.post<CommonResponse<CompanyLookup>>(
    "/api/v1/vat/validate",
    data,
    {
      apiSource: "node-auth",
      schema: CompanyLookupSchema,
    },
  );

  if (response.data.success!.data.valid === false) {
    throw new Error("Company not found");
  }

  return response.data.success!.data;
};

export const useLookUpCompanyMutation = () => useMutation(lookUpCompany);
