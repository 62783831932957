import axios from "axios";
import { useMutation } from "react-query";

import { ToastNotification } from "@ag/utils/services";

import { Company } from "../entities/company";

const updateCompany = async (
  userId: string,
  companyId: string,
  data: Partial<Company>,
) => {
  await axios.put(`/api/v1/users/${userId}/company/${companyId}`, data, {
    apiSource: "node-auth",
  });
};

export const useUpdateCompanyByIdMutation = () =>
  useMutation(
    ({
      userId,
      companyId,
      data,
    }: {
      userId: string | undefined;
      companyId: string | undefined;
      data: Partial<Company>;
    }) => {
      if (!userId) return Promise.reject("UserId was not provided");
      if (!companyId) return Promise.reject("Company ID was not provided");

      return updateCompany(userId, companyId, data);
    },
    {
      onError: error => {
        ToastNotification.error(error);
      },
    },
  );
