import { z } from "zod";

const AddressSchema = z.object({
  address: z.string(),
  city: z.string(),
  countryCode: z.string(),
  zipCode: z.string(),
});

export const CompanySchema = z.object({
  id: z.string(),
  userId: z.string(),
  name: z.string(),
  registrationNumber: z.string().nullable(),
  vatNumber: z.string(),
  vatValidatedAt: z.string().nullable(),
  address: AddressSchema,

  contractSignerFirstName: z.string().nullable(),
  contractSignerLastName: z.string().nullable(),
  contractSignerEmail: z.string().nullable(),
  contractSignerPhoneNumber: z.string().nullable(),
});

export const CompanyLookupSchema = z.object({
  address: z.string(),
  city: z.string(),
  name: z.string(),
  vatNumber: z.string(),
  zipCode: z.string(),
  valid: z.boolean(),
});

export type Company = z.infer<typeof CompanySchema>;

export type CompanyLookup = z.infer<typeof CompanyLookupSchema>;
