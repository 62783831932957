import { z } from "zod";

export const UserSchema = z.object({
  email: z.string().email(),
  firstName: z.string(),
  id: z.number(),
  lastName: z.string(),
  name: z.string(),
  preferredCurrency: z.string(),
});

export const HarvestYearsDataSchema = z.object({
  contractYearsAll: z.array(z.number()),
  harvestYearsAll: z.array(z.number()),
  harvestYearsOpen: z.array(z.number()),
});

export const YearFlowDataSchema = z.object({
  carbonFieldCreatingYear: z.number(),
  carbonFieldSetupYears: z.array(z.number()),
  carbonFieldActualsSetupYears: z.array(z.number()),
  carbonFieldActualsSubmitYear: z.number(),
  carbonFieldActualsYears: z.array(z.number()),
  carbonFieldValidationYears: z.array(z.number()),
});

export const ISOCropTypeSchema = z.object({
  id: z.string(),
  coolfarmIdentifier: z.number(),
  name: z.string(),
  identifier: z.string(),
  harvestYear: z.number(),
  netGross: z.number(),
  createdAt: z.string(),
  updatedAt: z.string(),
  showResidue: z.boolean().nullable(),
  residueAmount: z.number().nullable(),
});
