import axios from "axios";
import { useMutation } from "react-query";

import { ToastNotification } from "@ag/utils/services";

import { Company } from "../entities/company";

const updateCompany = async (userId: string, data: Partial<Company>) => {
  await axios.put(`/api/v1/users/${userId}/company`, data, {
    apiSource: "node-auth",
  });
};

export const useUpdateCompanyMutation = (userId: string | undefined) =>
  useMutation(
    (data: Partial<Company>) => {
      if (!userId) return Promise.reject("UserId was not provided");

      return updateCompany(userId, data);
    },
    {
      onError: error => {
        ToastNotification.error(error);
      },
    },
  );
