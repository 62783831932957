import axios from "axios";
import { useMutation } from "react-query";

import { ToastNotification } from "@ag/utils/services";

import { useSessionContext } from "~features/authentication";

export type CreateCompanyData = {
  name: string;
  vatNumber: string;
  roles: string[];
  registrationNumber?: string;
  address: {
    address: string;
    city: string;
    countryCode: string;
    zipCode: string;
  };
};

const createCompany = async (data: CreateCompanyData) => {
  await axios.post("/api/v1/companies", data, {
    apiSource: "node-auth",
  });
};

export const useCreateCompanyMutation = () => {
  const { fetchCurrentUser } = useSessionContext();

  return useMutation(createCompany, {
    onSuccess: () => {
      // refetch current user to update company
      fetchCurrentUser();
    },
    onError: error => ToastNotification.error(error),
  });
};
