import { z } from "zod";

import { Locale } from "@ag/i18n";
import { UserRole, UserValidationState } from "@ag/utils/types";

import { CompanySchema } from "~features/company";

const FreightAndTradeSettingSchema = z.object({
  acceptedNewsletter: z.boolean(),
  acceptedTerms: z.boolean(),
  preferredCurrency: z.string(),
});

const ProfileSchema = z.object({
  firstName: z.string(),
  lastName: z.string(),
  name: z.string(),
  phoneNumber: z.string(),
});

export const CurrentUserSchema = z.object({
  id: z.string(),
  email: z.string(),
  validationState: z.nativeEnum(UserValidationState),
  startupPage: z.nullable(z.string()),
  locale: z.nativeEnum(Locale),
  confirmedAt: z.string(),
  profile: ProfileSchema,
  freightAndTradeSetting: FreightAndTradeSettingSchema,
  company: z.nullable(CompanySchema),
  companies: z.array(CompanySchema),
  roles: z.array(z.nativeEnum(UserRole)),
});

export type CurrentUser = z.infer<typeof CurrentUserSchema>;
