import { z } from "zod";

import {
  ContractStatus,
  ContractType,
  CreditingPeriod,
  LockInPeriod,
  Payout,
  Sanction,
} from "../types/contract";

export const ContractSchema = z.object({
  id: z.string(),
  buffer: z.number(),
  carbonContractGroupId: z.string(),
  companyId: z.string(),
  contractedYears: z.array(z.number()),
  name: z.string(),
  carbonFields: z.array(
    z.object({
      carbonFarmId: z.string(),
      id: z.string(),
      name: z.string(),
      sizeHa: z.number(),
    }),
  ),
  contractType: z.nativeEnum(ContractType),
  contractUrl: z.string().nullable(),
  creditingPeriod: z.nativeEnum(CreditingPeriod),
  deliveredAt: z.string().optional(),
  expiresAt: z.string().optional(),
  createdAt: z.string(),
  issuanceFee: z.number(),
  lockInPeriod: z.nativeEnum(LockInPeriod).nullable(),
  payouts: z.nativeEnum(Payout).nullable(),
  sanctions: z.nativeEnum(Sanction).nullable(),
  sentAt: z.string().optional(),
  signedAt: z.string().optional(),
  status: z.nativeEnum(ContractStatus),
  termsUrl: z.string().nullable(),
  updatedAt: z.string(),
});

export type Contract = z.infer<typeof ContractSchema>;
