import * as SelectPrimitive from "@radix-ui/react-select";
import type { SelectItemProps } from "@radix-ui/react-select";
import { cva } from "class-variance-authority";
import clsx from "clsx";
import { createContext, useContext } from "react";

import { useIsMobile } from "@ag/utils/hooks";
import { fixedForwardRef } from "@ag/utils/types";

import { Icon } from "~assets";
import { cn } from "~utils";

type SelectVariant = "default" | "full-width" | "compact";

type SelectContextValue = {
  variant: SelectVariant;
  // Pass down "isMobile" in context, so any changes are synced properly.
  isMobile: boolean;
};

const SelectContext = createContext<SelectContextValue | null>(null);

function useSelectContext() {
  const context = useContext(SelectContext);

  if (!context) {
    throw new Error("useSelectContext must be used within a SelectProvider");
  }

  return context;
}

/* -------------------------------------------------------------------------------------------------
 * Select
 * -----------------------------------------------------------------------------------------------*/
export type SelectProps = React.PropsWithChildrenRequired<{
  className?: string;
  name?: string;
  maxChars?: number;
  placeholder?: string;
  value?: string | null;
  variant?: "default" | "full-width" | "compact";
  optionsClassName?: string;
  isDisabled?: boolean;
  isInvalid?: boolean;
  testId?: string;
  labelledBy?: string;
  onChange?: (value: string) => void;
}>;

const triggerVariants = cva(
  [
    "group",
    "inline-flex items-center justify-between gap-3",
    "min-w-[17ch] px-4 py-[10px]",
    "whitespace-nowrap",
    "rounded",
    "border border-solid",
    "cursor-pointer text-p2",
    "data-[placeholder]:text-grey-500",
    "focus:border-accent-200 focus:outline-none",
    "[&+select]:top-0",
  ],
  {
    variants: {
      variant: {
        "full-width": "w-full",
        compact: "min-w-[auto]",
        default: {},
      },
      isInvalid: {
        true: "border-messaging-error-700",
      },
      isDisabled: {
        true: "border-grey-300 bg-grey-300",
        false: "border-grey-700 bg-grey-white",
      },
    },
    compoundVariants: [
      {
        isInvalid: false,
        isDisabled: false,
        class: "data-[state=open]:border-accent-200",
      },
    ],
  },
);

const Select = fixedForwardRef(SelectComponent);

function SelectComponent(
  {
    children,
    name,
    placeholder,
    className,
    optionsClassName,
    value,
    variant = "default",
    isDisabled = false,
    isInvalid = false,
    onChange,
    testId,
    labelledBy,
  }: SelectProps,
  ref: React.Ref<HTMLButtonElement | HTMLSelectElement>,
) {
  const isMobile = useIsMobile();

  if (isMobile) {
    return (
      <SelectContext.Provider value={{ variant, isMobile: true }}>
        <div className="grid items-center">
          <select
            ref={ref as React.Ref<HTMLSelectElement>}
            value={value === null ? undefined : value}
            onChange={ev => onChange?.(ev.target.value)}
            className={clsx(
              triggerVariants({ variant, isDisabled, isInvalid }),
              "col-[1/2] row-[1/2] appearance-none",
              className,
            )}
          >
            {children}
          </select>

          <Icon
            name="chevron-down"
            className="col-[1/2] row-[1/2] mr-3 justify-self-end"
          />
        </div>
      </SelectContext.Provider>
    );
  }

  return (
    <SelectContext.Provider value={{ variant, isMobile: false }}>
      <SelectPrimitive.Root
        name={name}
        value={value === null ? undefined : value}
        disabled={isDisabled === true}
        onValueChange={onChange}
      >
        <SelectPrimitive.Trigger
          ref={ref as React.Ref<HTMLButtonElement>}
          data-testid={testId}
          aria-labelledby={labelledBy}
          className={clsx(
            triggerVariants({ variant, isDisabled, isInvalid }),
            className,
          )}
        >
          <span
            className={cn(
              "inline-block truncate",
              variant === "full-width"
                ? "max-w-[calc(100%-32px)]"
                : "max-w-[32ch]",
            )}
          >
            <SelectPrimitive.Value placeholder={placeholder} />
          </span>

          <SelectPrimitive.Icon
            className={cn(
              "text-accent-200",
              "group-data-[disabled]:text-gray-700 group-data-[state=open]:rotate-180",
            )}
          >
            <Icon name="chevron-down" />
          </SelectPrimitive.Icon>
        </SelectPrimitive.Trigger>

        <SelectPrimitive.Portal>
          <SelectPrimitive.Content
            className={cn(
              "rounded border border-grey-700 bg-grey-white",
              "w-full min-w-[--radix-select-trigger-width] overflow-hidden",
              "z-banner text-p2 text-grey-900 shadow-200",
              optionsClassName,
            )}
            position="popper"
            sideOffset={4}
          >
            <SelectPrimitive.Viewport className="relative grid max-h-44 overflow-y-scroll py-2">
              {children}
            </SelectPrimitive.Viewport>
          </SelectPrimitive.Content>
        </SelectPrimitive.Portal>
      </SelectPrimitive.Root>
    </SelectContext.Provider>
  );
}

type SelectGroupProps = Pick<
  SelectPrimitive.SelectGroupProps,
  "children" | "className"
> & {
  label: string;
};

/* -------------------------------------------------------------------------------------------------
 * SelectGroup
 * -----------------------------------------------------------------------------------------------*/
function SelectGroup({ label, children, ...rest }: SelectGroupProps) {
  const context = useSelectContext();

  if (context.isMobile) {
    return (
      <optgroup label={label} {...rest}>
        {children}
      </optgroup>
    );
  }

  return (
    <SelectPrimitive.Group
      className={cn("mt-2 divide-y divide-grey-600 first-of-type:mt-0")}
      {...rest}
    >
      <SelectPrimitive.Label className="py-1 pl-4 text-p3 text-grey-700">
        {label}
      </SelectPrimitive.Label>
      <div>{children}</div>
    </SelectPrimitive.Group>
  );
}

/* -------------------------------------------------------------------------------------------------
 * SelectOption
 * -----------------------------------------------------------------------------------------------*/
function SelectOption(
  props: Pick<
    SelectItemProps,
    "textValue" | "value" | "children" | "className"
  >,
) {
  const { value, className, children, textValue, ...rest } = props;
  const context = useSelectContext();

  if (context.isMobile) {
    return (
      <option value={value} label={textValue} {...rest}>
        {children}
      </option>
    );
  }

  return (
    <SelectPrimitive.Item
      value={value}
      {...rest}
      className={cn(
        "flex items-center",
        "relative px-4 py-2",
        "user-select-none cursor-pointer text-grey-900",
        "data-[disabled]:pointer-events-none data-[disabled]:text-grey-700",
        "data-[highlighted]:bg-sky-100",
        "data-[state=checked]:bg-accent-100 data-[state=checked]:text-grey-white",
        className,
      )}
    >
      <span
        className={cn(
          "inline-block truncate",
          context.variant === "full-width"
            ? "max-w-[calc(100%-32px)]"
            : "max-w-[32ch]",
        )}
        // used to let the user read full text when using truncation
        title={typeof children === "string" ? children : undefined}
      >
        <SelectPrimitive.ItemText>{children}</SelectPrimitive.ItemText>
      </span>
    </SelectPrimitive.Item>
  );
}

/* -------------------------------------------------------------------------------------------------
 * SelectOptionAll
 * -----------------------------------------------------------------------------------------------*/
function SelectOptionAll({
  children,
  textValue,
  ...rest
}: Pick<SelectItemProps, "children" | "aria-label" | "textValue">) {
  const context = useSelectContext();

  if (context.isMobile) {
    return (
      <option {...rest} label={textValue} value="">
        {children}
      </option>
    );
  }
  return (
    <SelectOption {...rest} value="">
      {children}
    </SelectOption>
  );
}

/* -------------------------------------------------------------------------------------------------
 * SelectOptionNone
 * -----------------------------------------------------------------------------------------------*/
function SelectOptionNone({
  children,
  textValue,
  ...rest
}: Pick<SelectItemProps, "children" | "aria-label" | "textValue">) {
  const context = useSelectContext();

  if (context.isMobile) {
    return (
      <option value="" label={textValue} {...rest}>
        {children}
      </option>
    );
  }

  return (
    <SelectOption {...rest} value="">
      {children}
    </SelectOption>
  );
}

const Root = Select;
const Option = SelectOption;
const OptionAll = SelectOptionAll;
const OptionNone = SelectOptionNone;
const Group = SelectGroup;

export { Root, Option, OptionAll, OptionNone, Group };
