import axios from "axios";
import {
  InfiniteData,
  UseQueryOptions,
  useInfiniteQuery,
  useQuery,
} from "react-query";

import {
  CommonPaginatedResponse,
  Meta,
  MetaArgs,
  createPaginatedDataSchema,
} from "@ag/utils/types";

import { Contract, ContractSchema } from "../entities/contract";
import { ContractStatus } from "../types/contract";

type ContractsFilters = {
  status?: ContractStatus[];
  contractedYears?: number[];
};

type FetchContractsMeta = Meta & {
  defaultContractId: number;
};

type UseContractsQueryOptions = {
  enabled?: boolean;
  onSuccess?: (
    contractsResult: InfiniteData<{
      items: Contract[];
      meta: FetchContractsMeta;
    }>,
  ) => void;
};

type UseContractsQueryParams = MetaArgs & {
  filters?: ContractsFilters;
};

type GetContractsParams = MetaArgs & UseContractsQueryParams;

export const generateContractsQueryKey = (params?: GetContractsParams) =>
  ["contracts", params].filter(Boolean);

const fetchContracts = async (params?: UseContractsQueryParams) => {
  const response = await axios.get<CommonPaginatedResponse<Contract>>(
    "/api/v1/contracts",
    {
      apiSource: "node-carbon",
      schema: createPaginatedDataSchema(ContractSchema),
      params: {
        sort: { id: "desc" },
        ...params,
      },
    },
  );

  return response.data.success!.data;
};

export const useContractsInfinityQuery = (
  params: UseContractsQueryParams,
  options?: UseContractsQueryOptions,
) =>
  useInfiniteQuery(
    generateContractsQueryKey(params),
    ({ pageParam = 1 }) => fetchContracts({ ...params, page: pageParam }),
    {
      getNextPageParam: prev => (prev?.meta.currentPage || 0) + 1,
      ...options,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    },
  );

export const useContractsQuery = (
  params?: UseContractsQueryParams,
  options?: Pick<UseQueryOptions, "enabled">,
) =>
  useQuery(
    generateContractsQueryKey(params),
    () => fetchContracts(params),
    options,
  );
