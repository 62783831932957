import qs from "qs";
import { useEffect } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useIntercom } from "react-use-intercom";
import { useEffectOnceWhen } from "rooks";

import { CurrentUserActivityEvent } from "@ag/utils/constants";
import { getSearchParams } from "@ag/utils/helpers";

import FullPageSpinner from "~components/FullPageSpinner";
import { useSessionContext } from "~features/authentication";
import {
  ampli,
  experiment,
  sessionReplay,
  useAmplitudeContext,
} from "~lib/ampli";
import { addCurrentUserToSentry } from "~lib/sentry";

import { useRecordCurrentUserActivityMutation } from "../api/record-current-user-activity";
import { useSessionRefresh } from "../hooks/use-session-refresh";

type Props = {
  children: React.ReactElement;
};
export const AuthorizedRoute = ({ children }: Props) => {
  const {
    currentUser,
    isSignedIn,
    isAdminMode,
    isLoading: isSessionLoading,
  } = useSessionContext();
  const { isSessionRefreshing } = useSessionRefresh();
  const { identifyUser } = useAmplitudeContext();

  const { mutate: recordCurrentUserActivityMutate } =
    useRecordCurrentUserActivityMutation();

  const location = useLocation();

  const intercom = useIntercom();

  useEffect(() => {
    if (!currentUser || isAdminMode) return;

    recordCurrentUserActivityMutate({
      event: CurrentUserActivityEvent.PageLoad,
      parameters: {
        url: location.pathname,
        params: qs.parse(location.search.slice(1)),
      },
    });
  }, [
    currentUser,
    isAdminMode,
    location.pathname,
    location.search,
    recordCurrentUserActivityMutate,
  ]);

  useEffectOnceWhen(() => {
    if (!currentUser) return;

    addCurrentUserToSentry(currentUser);

    identifyUser(currentUser.email, {
      isAdminMode,
    });
    // If the user is signed in, we assume logged in means statistics consent (ask Aleksander Shibilev for details if needed)
    ampli.client.setOptOut(false);
    ampli.client.add(sessionReplay);
    experiment.setUser({ user_id: currentUser.email });
    experiment.start();

    intercom.update({
      name: currentUser.profile.name,
      email: currentUser.email,
      userId: currentUser.id.toString(),
    });

    return () => {
      ampli.client.flush();
      ampli.client.reset();
      experiment.stop();
    };
  }, isSignedIn);

  if ((isSessionRefreshing && !currentUser) || isSessionLoading) {
    return <FullPageSpinner />;
  }

  if (!isSignedIn) {
    const redirectParams = getSearchParams({
      redirect: location.pathname,
    });
    const unauthorizedRedirectPath = `/login${redirectParams}`;

    return <Navigate to={unauthorizedRedirectPath} replace />;
  }

  return children;
};

export default AuthorizedRoute;
