import axios from "axios";
import { useQuery } from "react-query";

import { ToastNotification } from "@ag/utils/services";
import { CommonResponse } from "@ag/utils/types";

import { Company, CompanySchema } from "../entities/company";

const companyQueryKey = "comapny" as const;

const getCompany = async (userId: string) => {
  const response = await axios.get<CommonResponse<Company>>(
    `/api/v1/users/${userId}/company`,
    {
      schema: CompanySchema,
      apiSource: "node-auth",
    },
  );

  return response.data.success!.data;
};

export const useCompanyQuery = (userId: string | undefined) =>
  useQuery(
    companyQueryKey,
    () => {
      if (!userId) return Promise.resolve(undefined);

      return getCompany(userId);
    },
    {
      onError: error => {
        ToastNotification.error(error);
      },
    },
  );
