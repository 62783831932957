import { Global as GlobalStyles } from "@emotion/core";
import { createRoot } from "react-dom/client";
import TagManager from "react-gtm-module";
import { QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { BrowserRouter } from "react-router-dom";
import { IntercomProvider } from "react-use-intercom";
import { QueryParamProvider } from "use-query-params";
import { ReactRouter6Adapter } from "use-query-params/adapters/react-router-6";

import { DialogProvider } from "@ag/utils/hooks";

import App from "~app";
import { env } from "~config";
import AppHistoryProvider from "~contexts/app-history";
import { LocaleProvider } from "~contexts/locales";
import { SessionProvider } from "~features/authentication";
import {
  MaintenanceMode,
  isMaintenanceModeEnabled,
} from "~features/maintenance-mode";
import { AmplitudeProvider } from "~lib/ampli";
import "~lib/axios";
import { setupCookiebot } from "~lib/cookiebot";
import { setupI18n } from "~lib/i18n";
import { queryClient } from "~lib/react-query";
import { setupSentry } from "~lib/sentry";
import "~style/containers.css";
import { globalStyles } from "~style/global-styles";

import "./index.css";

setupCookiebot();
setupI18n();
setupSentry();

TagManager.initialize({
  gtmId: env.REACT_APP_GTM_ID ?? "",
});

const container = document.getElementById("root");
const root = createRoot(container!);

root.render(
  isMaintenanceModeEnabled ? (
    <MaintenanceMode />
  ) : (
    <BrowserRouter>
      <QueryParamProvider adapter={ReactRouter6Adapter}>
        <QueryClientProvider client={queryClient}>
          <AmplitudeProvider>
            <LocaleProvider>
              <AppHistoryProvider>
                <IntercomProvider
                  appId={env.REACT_APP_INTERCOM_APP_ID ?? ""}
                  autoBoot={true}
                >
                  <GlobalStyles styles={globalStyles} />

                  <SessionProvider>
                    <DialogProvider>
                      <App />
                    </DialogProvider>
                  </SessionProvider>

                  <ReactQueryDevtools
                    initialIsOpen={false}
                    position="bottom-right"
                  />
                </IntercomProvider>
              </AppHistoryProvider>
            </LocaleProvider>
          </AmplitudeProvider>
        </QueryClientProvider>
      </QueryParamProvider>
    </BrowserRouter>
  ),
);
