import { useMemo, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";

import { ComboBox } from "@ag/design-system/molecules";
import {
  ComboBoxField,
  InputField,
  PhoneNumberInputField,
} from "@ag/form-fields";
import I18n from "@ag/i18n";

import { useCarbonCountriesQuery } from "~queries/carbon/carbon-countries";
import { FormValues } from "~views/my-account/v2_my-account";

type Props = {
  index: number;
};

const EditCompanyForm = ({ index }: Props) => {
  const {
    control,
    register,
    formState: { errors },
  } = useFormContext<FormValues>();
  const [isEditable] = useState(true);

  const countries = useCarbonCountriesQuery();

  const companyErrors = errors.companies?.[index] || {};

  const countriesOptions = useMemo(
    () =>
      countries.data?.map(country => ({
        label: country.name,
        value: country.code,
      })),
    [countries],
  );

  return (
    <section className="grid gap-4 lg:grid-cols-2">
      <div className="flex flex-col gap-4">
        <p className="text-p1 font-bold">
          {I18n.t("js.my.profile.edit.basic_info")}
        </p>

        <InputField
          {...register(`companies.${index}.name`)}
          label={I18n.t("js.my.profile.edit.company_name")}
          disabled={!isEditable}
          error={companyErrors.name}
          required
        />

        <InputField
          {...register(`companies.${index}.address.address`)}
          label={I18n.t("js.my.profile.edit.company_address")}
          error={companyErrors.address?.address}
          disabled={!isEditable}
        />

        <div className="grid grid-cols-2 gap-2">
          <InputField
            {...register(`companies.${index}.address.city`)}
            label={I18n.t("js.my.profile.edit.company_city")}
            error={companyErrors.address?.city}
            disabled={!isEditable}
          />

          <InputField
            {...register(`companies.${index}.address.zipCode`)}
            error={companyErrors.address?.zipCode}
            label={I18n.t("js.my.profile.edit.company_postcode")}
            disabled={!isEditable}
          />
        </div>

        <Controller
          name={`companies.${index}.address.countryCode`}
          control={control}
          render={({ field }) => (
            <ComboBoxField
              {...field}
              emptyText={I18n.t("js.shared.no_matching_results")}
              error={companyErrors.address?.countryCode}
              label={I18n.t("js.my.profile.edit.company_country")}
              loadingText={I18n.t("js.shared.loading")}
              menuTriggger="focus"
              optionsClassName="z-modal"
              placeholder={I18n.t(
                "js.missing_profile_information.form.country.placeholder",
              )}
              isLoading={countries.isLoading}
            >
              {countriesOptions?.map(option => (
                <ComboBox.Item key={option.value}>{option.label}</ComboBox.Item>
              ))}
            </ComboBoxField>
          )}
        />
      </div>

      <div className="flex flex-col gap-4">
        <p className="text-p1 font-bold">Contract Signer</p>
        <div className="grid grid-cols-2 gap-2">
          <InputField
            {...register(`companies.${index}.contractSignerFirstName`)}
            label={I18n.t("js.my.profile.edit.signer_first_name")}
            error={companyErrors.contractSignerFirstName}
          />

          <InputField
            {...register(`companies.${index}.contractSignerLastName`)}
            label={I18n.t("js.my.profile.edit.signer_last_name")}
            error={companyErrors.contractSignerLastName}
          />
        </div>

        <InputField
          {...register(`companies.${index}.contractSignerEmail`)}
          label={I18n.t("js.my.profile.edit.signer_email")}
          error={companyErrors.contractSignerEmail}
        />

        <PhoneNumberInputField
          control={control}
          label={I18n.t("js.my.profile.edit.signer_phone_number_sms")}
          countryCodeInputName={`companies.${index}.contractSignerCountryCode`}
          phoneNumberInputName={`companies.${index}.contractSignerPhoneNumber`}
        />
      </div>
    </section>
  );
};

export default EditCompanyForm;
